.contain {
    max-width: 500px;
    margin: 0 auto;
    padding: 20px;
  }
  
  h1 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .item {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    padding: 10px;
    background-color: #f5f5f5;
  }
  
  .item-info {
    display: flex;
    align-items: center;
    flex-grow: 1;
  }
  
  .food-icon {
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }
  
  .item-details {
    flex-grow: 1;
  }
  
  .item h3 {
    margin: 0;
  }
  
  .item-quantity {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  
  .price {
    margin: 0;
  }
  
  .quantity-controls {
    display: flex;
    align-items: center;
  }
  
  .quantity-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border: none;
    background-color: #dcdcdc;
    color: #333;
    font-size: 18px;
    cursor: pointer;
  }
  
  .quantity-input {
    width: 50px;
    text-align: center;
    -moz-appearance: textfield; /* Remove up-down arrows in Firefox */
    -webkit-appearance: none; /* Remove up-down arrows in Chrome and Safari */
  }
  
  .total {
    margin-top: 5px;
  }
  
  .total-price {
    margin-top: 20px;
    text-align: right;
    font-weight: bold;
  }
  input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}